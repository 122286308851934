.reasons {

    padding: 0 2rem;
    display: flex;
    gap: 2rem; 
}

.leftR {

    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;

}

.leftR>img {

    object-fit: cover;
}

.leftR>:nth-child(1) {

    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}

.leftR>:nth-child(2) {

    width: auto;
    height: 16rem;
    grid-column: 2/4;
}


.leftR>:nth-child(3) { 

    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}


.leftR>:nth-child(4){

    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
}

.rightR {

    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.rightR>span {

    font-weight: bold;
    color: var(--blue);
}

.rightR>div {

   color: white;
   font-size: 3rem;
   font-weight: bold; 
}

.detailsR {

    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detailsR >div {

    display: flex;
    font-size: 1rem;
    gap: 1rem;

}
.detailsR >div>img{ 

    width: 2rem;
    height: 2rem;
}

.partners {

    display: flex;
    gap: 1rem;

}

.partners>img {
    width: 2.5rem;
}

@media screen and (max-width: 768px) {

    .reasons {

        flex-direction: column;
    }

    .leftR {

        grid-auto-rows: auto;
        overflow: hidden;
    }

    
    .leftR>:nth-child(1) {

        width: 7rem;
        height: 17rem;
    }

    .leftR>:nth-child(2) {

        width: 10rem;
        height: 10rem;
    }

    .leftR>:nth-child(3) {

        width: 7rem;

    }

    .leftR>:nth-child(4) {

        width: 7rem;
        height: 6rem;
    }
 
}