.footerC {

    position: relative;
}

.footerC>hr {

    border: 1px solid var(--lightgray)
}


.footer {

    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}



.socialLinks {
    
    display: flex;
    gap: 4rem;
    
}

.socialLinks>img { 

    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logoF {

    width: 18rem;
    
}

.blurF1 {

    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background-color: darkblue;
}

.blurF2 {

    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background-color: darkblue;
}

@media screen and (max-width: 768px) {

    .logoF {

        width: 19rem;
        margin-bottom: 100px;
        
    }
}